import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const MailManagerPage = () => (
  <div>
      <SEO 
        title="Web Stats Manager - All of the stats you will need in one place" 
        description=" Using our Web Control Panel, you will be able to supervise every aspect 
        of your web presence. You’ll have at hand complete data, which will present you with real–time information on the 
        resources your web sites are consuming along with the visitors they are getting."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title"> <span className="block text-indigo-600 xl:inline">Web Stats</span> Manager <span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">All of the stats you will need in one place</p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <p className="m-0">
                        <img src="https://sheephostingbay.duoservers.com/template2/img/email-manager-banner.webp" alt="Sheephostingbay Web control panel"/>
                    </p>
                  </div>
              </div>
              
            </div>
        </div>
        
    </div>

    <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                 <p className="section-after-title">
                 <span role="img" aria-label="emoji">🦄</span> Using our Web Control Panel, you will be able to supervise every aspect 
                 of your web presence. You’ll have at hand complete data, which will present you with real–time information on the 
                 resources your web sites are consuming along with the visitors they are getting. You will also find some relevant 
                 details about the system in general. The info is broken into parts, so you can easily find your path around.
                </p>
              
            </div>
        </div>
        
    </div>

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/server-information.webp" className="w-60" alt="Server Data" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Server Data</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Check out details about your server<br/><br/>
                                    If you want to check what exactly is the present syndication of PHP or MySQL or even the OS on the 
                                    server where your hosting account is located, just go to the Server Info area of your Web Control 
                                    Panel. There you’ll also get information about the mounted Perl modules, the incoming and 
                                    outbound email servers, plus the physical IP address of the server.
                                    <br/><br/>
                                    You can find the web hosting server details table inside the Statistics part of the Sheephostingbay 
                                    Web Control Panel.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Access & Error Records</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Immediately locate any site general performance issues<br/><br/>
                                    The Access and Error Listings record info concerning the hosting server, consisting of details of 
                                    server access in addition to the kinds of problems detected throughout web server functioning. 
                                    You’ll find both forms of info about the effectiveness of your websites from the Web Stats 
                                    Manager area of the Web Control Panel.
                                    <br/><br/>
                                    The access log displays each of the text files, image files, movie files, etc. that others have asked 
                                    for to view in your site, and the error log records all of the warnings and glitches that the web 
                                    server has stumbled upon since the log file is created.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/access-error-logs.webp" className="w-60" alt="Access & Error Records" />
                            </div>
                        </div>
                      
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/traffic-stats.webp" className="w-60" alt="Website Traffic Reports" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Website Traffic Reports</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Monitor the traffic to your site in detail<br/><br/>
                                    Through the web statistics tools built into the Sheephostingbay Web Control Panel, it is easy to monitor 
                                    the traffic on your website in the greatest detail. You can select in between a couple of popular 
                                    interfaces – Webalizer and Awstats, both of which present you with in–depth information about 
                                    the viewers to your site along with the site contents they request to view on a daily, weekly 
                                    and monthly basis.
                                    <br/><br/>
                                    You don’t have to put together anything upfront to get site traffic info for your web sites. Within the Web 
                                    Traffic Reports part of the Web Control Panel, just simply visit the statistics list for a chosen site and 
                                    view the facts that you require for your advertising strategies.
                                    </div>
                                </div>
                            </div>
                        </div>   

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">CPU Statistics</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Monitor your websites’ server power load<br/><br/>
                                    The web server’s CPU is important for the interaction and communication between the website and its 
                                    visitors. The more advanced and resource–consuming your websites are, the more hosting server 
                                    assets and CPU time will be required. From the CPU statistics section, it will be easy to 
                                    check which particular site is utilizing the majority of CPU allocations.
                                    <br/><br/>
                                    You need to take actions to enhance your web sites in case the CPU use quota is exceeded. You can 
                                    see detailed stats for each day and month as well as for a full calendar year.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/cpu-stats.webp" className="w-60" alt="CPU Statistics" />
                            </div>
                        </div>  

                  </div>
              </div>
          </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default MailManagerPage
